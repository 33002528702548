<template>
  <div class="user-management-container">
    <el-row ref="search_form">
      <el-col :span="24">
        <div class="search-box">
          <!-- 导出Excel -->
          <el-form :inline="true" size="medium" :model="searchForm">
            <el-form-item>
              <el-button type="primary" size="mini" @click="handleExcelImp"
                >媒体excel导入</el-button
              >
            </el-form-item>
            <!-- <el-form-item>
              <el-button type="primary" size="mini" @click="exportExcel"
                >工作人员excel导入</el-button
              >
            </el-form-item> -->
            <el-dialog
              title="excel导入"
              :visible.sync="excelImp.dialogVisible"
              width="60%"
              :close-on-click-modal="false"
            >
              <el-form>
                <el-row>
                  <el-col :span="24">
                    <a :href="baseUrl + '/template-media.xlsx'" target="_blank"
                      >点击下载导入模板</a
                    >
                  </el-col>
                </el-row>
                <el-row style="margin-top: 20px">
                  <el-col :span="24">
                    <el-upload
                      class="upload-demo"
                      ref="upload"
                      name="file"
                      :action="excelImp.action"
                      :multiple="false"
                      :limit="1"
                      accept=".xls,.xlsx"
                      :file-list="excelImp.fileList"
                      :auto-upload="false"
                      :on-success="onUploadSuccess"
                      :on-error="onUploadError"
                    >
                      <el-button slot="trigger" size="small" type="primary"
                        >选取文件</el-button
                      >
                      <!--              <el-button style="margin-left: 10px;" size="small" type="success" @click="submitUpload">上传到服务器</el-button>-->
                      <div slot="tip" class="el-upload__tip">
                        只能上传xls/xlsx
                      </div>
                    </el-upload>
                  </el-col>
                </el-row>
              </el-form>

              <div slot="footer" class="dialog-footer">
                <el-button @click="excelImp.dialogVisible = false"
                  >关 闭</el-button
                >
                <el-button
                  type="primary"
                  @click.native.prevent="submitUpload"
                  :loading="excelImp.loading"
                  >立即导入</el-button
                >
              </div>
            </el-dialog>
            <!-- <el-form-item>
              <el-button
                :plain="true"
                type="primary"
                size="mini"
                @click="exportExcelNew(10)"
                >导出总表_隐藏</el-button
              >
            </el-form-item>

            <el-form-item>
              <el-button
                :plain="true"
                type="success"
                size="mini"
                @click="exportExcelNewAll(10)"
                >导出总表</el-button
              >
            </el-form-item> -->

            <!--
            <el-form-item>
              <el-button plain type="primary" size="mini" @click="exportExcelNew(11)">导出政务贵宾表</el-button>
            </el-form-item>
            <el-form-item>
              <el-button plain type="primary" size="mini" @click="exportExcelNew(12)">导出政务嘉宾表</el-button>
            </el-form-item>
            <el-form-item>
              <el-button plain type="primary" size="mini" @click="exportExcelNew(13)">导出行业贵宾表</el-button>
            </el-form-item>
            <el-form-item>
              <el-button plain type="primary" size="mini" @click="exportExcelNew(14)">导出行业嘉宾表</el-button>
            </el-form-item>
            
            -->
          </el-form>
        </div>
      </el-col>
    </el-row>

    <!-- <edits ref="edit" @fetch-data="fetchData" /> -->
  </div>
</template>
  
  <script>
// import { getList } from "@/api/shanghu";
// import Edits from './model/edit.vue'
import { getToken } from "@/common/utils/auth";
import util from "@/common/utils/util.js";

export default {
  name: "Cduanyonghu",

  data() {
    return {
      searchForm: {},
      baseUrl: "",

      list: [],
      value: "",
      options1: [
        {
          id: "0",
          creClasName: "未审核",
        },
        {
          id: "1",
          creClasName: "审核通过",
        },
        {
          id: "2",
          creClasName: "审核被驳回",
        },
      ], //审核状态
      listLoading: true,
      layout: "total, sizes, prev, pager, next, jumper",
      total: 0,
      excelImp: {
        dialogVisible: false,
        loading: false,
        fileList: [],
        action: "",
        loadingDiv: "",
      },
      selectRows: "",
      queryForm: {
        pageNum: 1,
        pageSize: 10,
        keyword: "",
      },
    };
  },
  mounted() {
    let t = getToken();
    this.excelImp.action =
      this.$adminApiUrl + "/participate/excel-imp-media?userType=0&_t=" + t;
    this.baseUrl = this.$baseUrl;
  },

  methods: {
    submitUpload() {
      this.excelImp.loading = true;
      this.excelImp.loadingDiv = util.showLoading("导入中");
      this.$refs.upload.submit();
    },
    onUploadError(err, file, fileList) {
      this.excelImp.loading = false;
      this.excelImp.loadingDiv.close();
      this.$alert("导入失败", {
        dangerouslyUseHTMLString: true,
        type: "warning",
      });
    },
    onUploadSuccess(res, file, fileList) {
      this.excelImp.loading = false;
      this.excelImp.loadingDiv.close();

      if (res.code == 0) {
        this.$alert(res.msg, {
          dangerouslyUseHTMLString: true,
          type: "warning",
        });
      } else if (res.code == 1) {
        this.$alert(res.msg, {
          dangerouslyUseHTMLString: true,
          type: "success",
        }).then(() => {
          this.excelImp.dialogVisible = false;
        });
      }
    },
    handleExcelImp() {
      this.excelImp.fileList = [];
      this.excelImp.dialogVisible = true;
    },
    setSelectRows(val) {
      this.selectRows = val;
    },
    optosall() {
      console.log(this.queryForm.status);
    },
    handleEdit(row) {
      if (row.id) {
        this.$refs["edit"].showEdit(row);
      } else {
        this.$refs["edit"].showEdit();
      }
    },
    // handleDelete(row) {
    //   if (row.id) {
    //     this.$baseConfirm('你确定要删除当前项吗', null, async () => {
    //       const { msg } = await doDelete({ id: row.id })
    //       this.$baseMessage(msg, 'success', 'vab-hey-message-success')
    //       await this.fetchData()
    //     })
    //   } else {
    //     if (this.selectRows.length > 0) {
    //       const id = this.selectRows.map((item) => item.id).join()
    //       this.$baseConfirm('你确定要删除选中项吗', null, async () => {
    //         const { msg } = await doDelete({ id })
    //         this.$baseMessage(msg, 'success', 'vab-hey-message-success')
    //         await this.fetchData()
    //       })
    //     } else {
    //       this.$baseMessage('未选中任何行', 'error', 'vab-hey-message-error')
    //     }
    //   }
    // },
    handleSizeChange(val) {
      this.queryForm.pageSize = val;
      this.fetchData();
    },
    handleCurrentChange(val) {
      this.queryForm.pageNum = val;
      this.fetchData();
    },
    handleEditfour(row) {
      if (row.id) {
        this.$refs["Editxiugai"].showEdit(row);
      } else {
        this.$refs["Editxiugai"].showEdit();
      }
    },
    queryData() {
      this.queryForm.pageNum = 1;
      this.fetchData();
    },
    async fetchData() {
      this.listLoading = true;
      const res = await getList(this.queryForm);

      this.list = res.data.dataList;

      this.total = res.data.total;
      this.listLoading = false;
    },
  },
};
</script>
  <style>
.fengmiantu {
  width: 50px;
  height: 50px;
}
</style>
  